<template>
    <div>
        <p v-if="hintText">{{ hintText }}</p>
        <div class="cost-estimate__checkboxes">
            <v-checkbox :label="option.option.en" v-for="(option, index) in localOptions" :key="index"
                        v-model="option.checked"
                        @change="toggleTextFields(option)"></v-checkbox>
        </div>
        <div class="cost-estimate__input">
            <div v-for="(item, index) in selectedValues" :key="index">
                <v-text-field
                    filled
                    :label="$t('fields.damage.insert_value',{item: item.option.en})"
                    :placeholder="$t('fields.damage.insert_value',{item: item.option.en})"
                    @blur="updateAnswer('cost', $event, item)"
                    v-model="item.value"/>
                <v-divider class="mb-5"/>
                <div class="survey__question__media mb-5">
                    <div class="text-center mt-3">
                        <v-btn color="primary" depressed rounded block class="mb-3"
                               @click="onUploadImages('damage' + index)">
                            {{ $t('file.upload') }}
                            <v-icon right>cloud_upload</v-icon>
                        </v-btn>
                    </div>

                    <div class="file-input">
                        <v-file-input
                            :ref="'damage' + index"
                            v-model="file"
                            multiple
                            chips
                            @change="onFileChange($event, item)"
                            :placeholder="$t('file.placeholder')"
                            :label="$t('file.label')"
                        ></v-file-input>
                    </div>

                    <div v-if="imagesArray.length > 0">
                        <v-list>
                            <template v-for="(image) in imagesArray">
                                <template v-if="image.optionId === item.id">
                                    <template v-for="(file, index) in image.files">
                                        <v-list-item :key="index">
                                            <v-list-item-avatar width="80" height="80">
                                                <template v-if="file.type.includes('video')">
                                                    <v-icon>videocam</v-icon>
                                                </template>
                                                <template v-else>
                                                    <img :src="renderImage(file)" style="max-width:120px"/>
                                                </template>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                {{ $t("file.file_text", {fileNumber: index + 1}) }}
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </template>
                            </template>
                        </v-list>

                        <v-btn color="red" depressed rounded outlined block @click="deleteImages(item)" class="my-3">
                            {{ $t('file.remove') }}
                            <v-icon right>delete</v-icon>
                        </v-btn>
                    </div>
                </div><!-- End media -->
            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import constants from '../../../helpers/constants';

export default {
    name: 'Damage',
    props: {
        options: {
            type: Array,
            default: () => {
                return [];
            }
        },
        hintText: {
            default: null
        },
        questionId: {}
    },
    data() {
        return {
            localOptions: [],
            selectedValues: [],
            modifiedValues: [],
            imagesArray: [],
            file: null,
            imagePath: constants.cloudinaryUrlSmall
        };
    },
    computed: {
        ...mapState('surveys', ['currentSurveyAnswers'])
    },
    methods: {
        ...mapMutations('surveys', ['setFilesToQuestion']),
        onFileChange(files, option) {
            this.imagesArray.push({
                optionId: option.id,
                files: files
            });

            this.setFilesToQuestion({
                questionId: this.questionId,
                optionId: option.id,
                images: [...files]
            });

            this.updateAnswer('images', null, option);
        },
        renderImage(image) {
            const tempUrl = URL.createObjectURL(image);
            URL.revokeObjectURL(image);
            return tempUrl;
        },
        onUploadImages(ref) {
            this.$refs[ref][0].$refs.input.click();
        },
        deleteImages(option) {
            this.imagesArray.forEach((imageItem, index) => {
                if (imageItem.optionId === option.id) {
                    this.imagesArray.splice(index, 1);
                }
            });

            this.updateAnswer('images', null, option);
        },
        toggleTextFields(option) {
            if (option.checked) {
                option.checked = true;
                option.value = '';
                this.selectedValues.push(option);
            } else {
                option.checked = false;
                this.selectedValues = this.selectedValues.filter(function(item) {
                    return item !== option;
                });
            }
        },
        updateAnswer(type, event, option) {
            let imageNames;
            let images = null;
            let itemToPush;

            if (this.imagesArray.length > 0) {
                imageNames = this.imagesArray.reduce((acc, file) => {
                    if (file.optionId === option.id) {
                        file.files.forEach((file) => {
                            acc.push(file.name);
                        });
                    }
                    return acc;
                }, []);
                images = imageNames.join(',');
            }
            const currentOption = this.modifiedValues.find((item) => {
                return item[option.id];
            });

            if (type === 'cost') {
                if (currentOption) {
                    currentOption[option.id] = {
                        costEstimate: parseInt(event.target.value)
                    };
                } else {
                    itemToPush = {
                        [option.id]: {
                            costEstimate: parseInt(event.target.value)
                        }
                    };
                    this.modifiedValues.push(itemToPush);
                }
            }
            if (type === 'images') {
                if (currentOption) {
                    currentOption[option.id] = {
                        ...currentOption[option.id],
                        media: images
                    };
                } else {
                    itemToPush = {
                        [option.id]: {
                            costEstimate: 0,
                            media: images
                        }
                    };
                    this.modifiedValues.push(itemToPush);
                }
            }
            this.$emit('updateAnswer', this.modifiedValues);
        },
        checkForAnswer() {
            if (this.currentSurveyAnswers) {
                this.currentSurveyAnswers.forEach((answer) => {
                    if (answer[this.questionId]) {
                        this.options.forEach((option) => {
                            const currentAnswers = answer[this.questionId][0];
                            Object.keys(currentAnswers).forEach((item) => {
                                if (option.id === item) {
                                    option.checked = true;
                                    option.value = currentAnswers[item];
                                    this.selectedValues.push(option);
                                    this.modifiedValues.push({
                                        [this.questionId]: currentAnswers[item]
                                    });
                                }
                            });
                        });
                    }
                });
            }
        }
    },
    created() {
        this.localOptions = [...this.options];
        this.localOptions.forEach((option) => {
            option.checked = false;
            option.value = '';
        });
        this.checkForAnswer();
    }
};
</script>

<style>
.file-input .v-input__slot,
.file-input .v-input__prepend-outer {
    display: none !important;
}
</style>
